import { Box } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import fetchApi from "../api/fetchApi";
import Dialog from "../components/dialog/Dialog";
import CustomInput from "../components/inputs/cutsomInput";
import SelectFree from "../components/inputs/selectFree";
import useAuth from "../hooks/useAuth";

type FormCreateEditType =
  | {
      context: "edit";
      immo: any;
      show: boolean;
      setShow: any;
    }
  | {
      context: "create";
      immo: undefined;
      show: boolean;
      setShow: any;
    };

const FormCreateEditImmo = ({
  context,
  immo,
  show,
  setShow,
}: FormCreateEditType) => {
  const { handleSubmit, control, reset, register } = useForm();
  const [isSaving, setisSaving] = useState(false);
  const { token } = useAuth();
  const [image, setImage] = useState<any>();
  const queryClient = useQueryClient();
  const isEditing = context === "edit";

  const propertyArray = [
    { title: "concierge", value: immo?.concierge === "1" ? "Oui" : "Non" },
    { title: "parking", value: immo?.parking === "1" ? "Oui" : "Non" },
    { title: "ascenseur", value: immo?.ascenseur === "1" ? "Oui" : "Non" },
    { title: "piscine", value: immo?.piscine === "1" ? "Oui" : "Non" },
    { title: "balcon", value: immo?.balcon === "1 " ? "Oui" : "Non" },
    { title: "jardin", value: immo?.jardin === "1" ? "Oui" : "Non" },
  ];

  const updateImmo = async (data: any) => {
    setisSaving(true);
    const formData = new FormData();

    if (isEditing) {
      formData.append("id", Number(immo?.id).toString());
    }
    formData.append("nom", data?.nom);
    formData.append("titre", data?.description);

    if (image) {
      formData.append("image", image);
    }

    fetchApi(`people${isEditing ? "/update" : ""}`, token)
      .upload(formData)
      .then((e) => {
        if (e.error) {
          toast(e.messages.message);
        } else if (e.title === "TypeError") {
          toast(e.message);
        } else {
          queryClient.invalidateQueries({
            queryKey: ["ImmoDetails"],
          });
          queryClient.invalidateQueries({
            queryKey: ["Immobilier"],
          });
          handleCloseEdit();
          !isEditing && reset();
          toast("Les  détails  ont été modifié", {
            type: "success",
          });
        }
      })
      .catch((err) => toast(err.message, { type: "error" }))
      .finally(() => setisSaving(false));
  };

  const uploadimg = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      console.log(file);
      setImage(e.target.files[0]);
    }
  };

  const handleCloseEdit = () => {
    setShow(false);
  };

  return (
    <Dialog
      title={isEditing ? `Modifier ${immo?.nom}` : "Ajouter un membre"}
      handleClose={handleCloseEdit}
      open={show}
      confirm={handleSubmit(updateImmo)}
      isStoreActivated={false}
      isConfirming={isSaving}
      id={1}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <CustomInput
          control={control}
          id="nom"
          label="Nom"
          name="nom"
          defaultValue={immo?.nom || ""}
          disabled={isSaving}
          rules={{
            required: "This field is required",
          }}
          autoFocus
        />
      </Box>

      <CustomInput
        control={control}
        id="description"
        label="Position"
        name="description"
        disabled={isSaving}
        defaultValue={immo?.titre || ""}
      />
      <input type="file" {...register("file")} onChange={uploadimg} />
    </Dialog>
  );
};

export default FormCreateEditImmo;
