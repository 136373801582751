import { createTheme } from "@mui/material/styles";

const baseTheme = createTheme({
  typography: {
    fontFamily: "'Work Sans', sans-serif",
    fontSize: 14,
  },
});

const theme = createTheme({
  ...baseTheme,
  palette: {
    primary: {
      main: "#0C7C35",
    },
    secondary: {
      main: "#fff",
    },
  },
});

export default theme;
