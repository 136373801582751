import { Box } from "@mui/material";
import { useState } from "react";
import { Route, Routes } from "react-router-dom";
import Header from "../components/layout/header/header";
import SideNav from "../components/layout/sideNav/sideNav";
import Logout from "../pages/auth/logout";
import AutomobileDetailsPage from "../pages/automobiles/automobileDetailsPage";
import AutomobilePage from "../pages/automobiles/automobilePage";
import HomePage from "../pages/home/homePage";
import NotificationPage from "../pages/notification/notificationPage";
import SettingPage from "../pages/settings/settingPage";
import ImmobilierPage from "../pages/immobiliers/immobilierPage";
import ImmobilierDetailsPage from "../pages/immobiliers/immobilierDetailsPage";
import NewsPage from "../pages/news/newsPage";
import NewsDetailsPage from "../pages/news/newsDetailsPage";

function AppRouter() {
  const [open, setOpen] = useState(true);

  const handleDrawerOpenClose = () => {
    setOpen(!open);
  };

  return (
    <Box
      sx={{
        flexDirection: "row",
        alignItems: "center",
        display: "flex",
        flex: 1,
        width: "100%",
      }}
    >
      <SideNav open={open} handleDrawerOpenClose={handleDrawerOpenClose} />
      <Box sx={{ width: "100%" }}>
        <Header
          handleDrawerOpenClose={handleDrawerOpenClose}
          isHomePage={false}
        />
        <Routes>
          <Route path="/">
            <Route path="/" element={<HomePage />} />
          </Route>
          <Route path="/notifications" element={<NotificationPage />} />

          <Route path="/project" element={<AutomobilePage />} />
          <Route path="/project/:id" element={<AutomobileDetailsPage />} />

          <Route path="/people" element={<ImmobilierPage />} />
          <Route path="/people/:id" element={<ImmobilierDetailsPage />} />

          <Route path="/news" element={<NewsPage />} />
          <Route path="/news/:id" element={<NewsDetailsPage />} />

          <Route path="/settings" element={<SettingPage />} />
          <Route path="/logout" element={<Logout />} />
        </Routes>
      </Box>
    </Box>
  );
}

export default AppRouter;
