import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  IconButton,
  Typography,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { appHost } from "../../api/apihost";
import {
  useMutationAcceptLoanApplication,
  useMutationRejectLoanApplication,
} from "../../api/mutations";
import { useQueryNewsDetail } from "../../api/queries";
import Btn from "../../components/button/customButton";
import Card from "../../components/card/card";
import KeyValueText from "../../components/keyValueText/keyValueText";
import Loading from "../../components/layout/loading/Loading";
import { FormCreateEditNews, FormUploadImage } from "../../shared";
import { formatDate } from "../../utils/formatDate";

const NewsDetailsPage = () => {
  const { id } = useParams();

  const {
    mutate: rejectLoan,
    isPending,
    isSuccess,
  } = useMutationRejectLoanApplication();
  const {
    mutate: acceptLoan,
    isPending: isPendingAccept,
    isSuccess: isSuccessAccept,
  } = useMutationAcceptLoanApplication();
  const { data, isLoading } = useQueryNewsDetail(id);
  const [imageTobeUpdated, setImageTobeUpdated] = useState<string | null>("");
  const [showEdit, setShowEdit] = useState(false);

  const item = data?.data || null;

  const queryClient = useQueryClient();

  useEffect(() => {
    if (isSuccess || isSuccessAccept)
      queryClient.invalidateQueries({
        queryKey: ["Immobilier"],
      });
    queryClient.invalidateQueries({
      queryKey: ["ImmoDetails"],
    });
  }, [isSuccess, isSuccessAccept]);

  return (
    <>
      <Loading isLoading={isLoading} />
      {!isLoading && (
        <>
          <FormCreateEditNews
            item={item}
            show={showEdit}
            setShow={setShowEdit}
            context="edit"
          />
          <FormUploadImage
            car={item}
            show={Boolean(imageTobeUpdated)}
            setShow={setImageTobeUpdated}
            context="immo"
            imageLink={imageTobeUpdated}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            <Box>
              <Breadcrumbs aria-label="breadcrumb" sx={{ padding: 2 }}>
                <Link
                  color="inherit"
                  to="/"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Tableau de Bord
                </Link>
                <Link
                  color="inherit"
                  to="/news"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Actualités
                </Link>
                <Typography color="primary">{item?.titre}</Typography>
              </Breadcrumbs>
            </Box>
            <Box padding={2}>
              <Btn onClick={() => setShowEdit(true)}>Editer </Btn>
            </Box>
          </Box>
          <Divider />
          <Card title="Détails ">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                padding: 2,
              }}
            >
              <IconButton sx={{ p: 0 }}>
                <Avatar
                  sx={{
                    height: 100,
                    width: 100,
                    maxHeight: { xs: 100, md: 100 },
                    maxWidth: { xs: 100, md: 100 },
                  }}
                  alt={item?.nom}
                  src={
                    item?.image.startsWith("uploads")
                      ? `${appHost}${item?.image}`
                      : `${item?.image}`
                  }
                />
              </IconButton>

              <Typography sx={{ px: 3 }}>{item?.titre}</Typography>
            </Box>

            <Divider />

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                padding: 2,
                paddingX: 3,
              }}
            >
              <KeyValueText title="Description" value={""} isVertical />
              <div dangerouslySetInnerHTML={{ __html: item?.contenu }} />
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                padding: 2,
                paddingX: 3,
              }}
            >
              <Box sx={{ width: "50%" }}>
                <KeyValueText
                  title="Date de création"
                  value={`${formatDate(item?.registered)}`}
                  isVertical
                />
              </Box>
              <Box sx={{ width: "50%" }}>
                <KeyValueText
                  title="Status"
                  value={
                    <Chip
                      label={item.active === "1" ? `Actif ` : "Non actif"}
                      color={item.active === "1" ? "success" : "error"}
                    />
                  }
                  isVertical
                />
              </Box>
            </Box>
          </Card>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: 4,
              paddingBottom: 15,
            }}
          >
            <Box sx={{ width: "40%" }}>
              {item?.status === "open" && (
                <FormControlLabel
                  control={<Checkbox />}
                  label="All documents have been approved and verified"
                />
              )}
            </Box>
            <Box sx={{ width: "45%" }}>
              {item?.kycApproved === "completed" && (
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Button
                    type="submit"
                    fullWidth
                    disabled={isPending || isPendingAccept}
                    variant="contained"
                    size={"large"}
                    sx={{ mt: 3, mb: 2, mx: 2 }}
                    onClick={() => acceptLoan({ loanId: item?.id })}
                  >
                    Approve
                  </Button>

                  <Button
                    type="submit"
                    fullWidth
                    color="error"
                    variant="contained"
                    size={"large"}
                    disabled={isPending || isPendingAccept}
                    onClick={() => rejectLoan({ loanId: item?.id })}
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Reject
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default NewsDetailsPage;
