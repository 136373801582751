import fetchApi from "../fetchApi";
import useAuth from "../../hooks/useAuth";
import { useQuery } from "@tanstack/react-query";

const useQueryNews = (options?: any) => {
  const { token } = useAuth();
  return useQuery({
    queryKey: ["News"],
    queryFn: () => fetchApi("news", token).get(),
    staleTime: 10000 * 60 * 10,
    refetchOnWindowFocus: false,
  });
};

export default useQueryNews;
