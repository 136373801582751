import { Box, Chip, Divider, Typography } from "@mui/material";
import { useQueryStats, useQueryTransaction } from "../../api/queries";
import CardStat from "../../components/layout/cardStat/cardStat";
import Datagrid from "../../components/layout/customTable/Datagrid";
import Loading from "../../components/layout/loading/Loading";
import SectionLayout from "../../components/layout/sectionLayout";
import formatCurrency from "../../utils/formatCurrency";
import { formatDate } from "../../utils/formatDate";
import { newSelectObjectByProperties } from "../../utils/selectObjectByProperties";
import { DashboardChart } from "./DashboardChart";
import { app, colors } from "../../constants/common";

const HomePage = (props: any) => {
  //const { data } = useQueryTransaction();
  {
    /**const columns = [
    { label: "Farm Name", property: "name" },
    { label: "Phone", property: "phonenumber" },

    { label: "Date", property: "created_at", formatter: formatDate },
    {
      label: "Status",
      property: "status",
      formatter: (text: string | number) => (
        <Chip
          color={
            text === "successful"
              ? "primary"
              : text === "pending"
              ? "info"
              : "error"
          }
          label={`${text}`}
          sx={{
            bgcolor:
              text === "successful"
                ? "#E5F4EE"
                : text === "pending"
                ? "#E9D5D5"
                : "#E9D5D5",
            color:
              text === "successful"
                ? "#359444"
                : text === "pending"
                ? "#9C7955"
                : "#F86868",
          }}
        />
      ),
    },
    { label: "Amount", property: "amount", formatter: formatCurrency }, // Assurez-vous que la propriété "action" existe dans vos objets row
  ]; */
  }

  // const { isLoading, data: dataStats } = useQueryStats();
  //const transactions = data?.data.slice(0, 5) || [];
  //const selectedRows = newSelectObjectByProperties(transactions, columns);

  return (
    <>
      {/*<Loading isLoading={isLoading} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <CardStat
          title="Total Income"
          amount={formatCurrency(dataStats?.data?.income || 0)}
        />
        <CardStat
          title="Small Holder Farm"
          amount={dataStats?.data.smallFarmNumber || "-"}
        />
        <CardStat
          title="Individual Farms"
          amount={dataStats?.data.individualFarmNumber || "-"}
        />
      </Box>
      <SectionLayout title="Income">
        <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <Box sx={{ height: "40vh", width: "100%", alignSelf: "center" }}>
            <DashboardChart />
          </Box>
        </Box>
      </SectionLayout>
      <Divider />
      <SectionLayout title="Recents Transactions">
        <Datagrid columns={columns} rows={selectedRows} />
      </SectionLayout>*/}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          marginTop: 5,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Box
            sx={{
              marginBottom: { xs: "unset", md: "3" },
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              variant={"h3"}
              sx={{ textAlign: { xs: "center", sm: "center" } }}
            >
              Bienvenue sur la page Admin de
            </Typography>
            <Typography
              variant={"h2"}
              color={colors.primary}
              sx={{ textAlign: { xs: "center", sm: "center" } }}
            >
              {app.name}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default HomePage;
