import {
  Avatar,
  Box,
  Breadcrumbs,
  Dialog,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { useQueryFarmDetail } from "../../api/queries";
import Card from "../../components/card/card";
import KeyValueText from "../../components/keyValueText/keyValueText";
import Loading from "../../components/layout/loading/Loading";
import useAuth from "../../hooks/useAuth";

import { Close } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import ChangePassword from "./changePassword";
import { app } from "../../constants/common";

const useStyles = makeStyles((theme) => {
  const appbarHeight = 0;
  return {
    root: { top: `${appbarHeight}px !important` },
  };
});

const SettingPage = () => {
  const { id } = useParams();
  const { user } = useAuth();
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <>
        <Breadcrumbs aria-label="breadcrumb" sx={{ padding: 2 }}>
          <Link
            color="inherit"
            to="/"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            Tableau de bord
          </Link>
          <Typography color="#359444">Settings</Typography>
        </Breadcrumbs>

        <Divider />
        <Card title="Profile">
          <Box
            sx={{
              display: "flex",

              alignItems: "center",
              padding: 2,
            }}
          >
            <IconButton sx={{ p: 0 }}>
              <Avatar alt={user?.name} src={app.logo} />
            </IconButton>

            <Typography sx={{ px: 3 }}>{user?.name}</Typography>
          </Box>

          <Divider />
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              padding: 2,
              paddingX: 3,
            }}
          >
            <Box sx={{ width: "50%" }}>
              <KeyValueText
                title="Email Address"
                value={user?.email}
                isVertical
              />
            </Box>
            <Box sx={{ width: "50%" }}>
              <KeyValueText title="Role" value="Admin" isVertical />
            </Box>
          </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              padding: 2,
              paddingX: 3,
            }}
          >
            <Box sx={{ width: "50%" }}>
              <Typography
                onClick={handleClickOpen}
                color={"blue"}
                sx={{ cursor: "pointer" }}
              >
                Change Password
              </Typography>
            </Box>
          </Box>
        </Card>
      </>

      <Dialog
        className={classes.root}
        fullScreen
        open={open}
        onClose={handleClose}
      >
        <Box
          sx={{
            width: "100%",
            alignItems: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            sx={{ alignSelf: "flex-end" }}
          >
            <Close />
          </IconButton>
        </Box>
        <ChangePassword close={handleClose} />
      </Dialog>
    </>
  );
};

export default SettingPage;
