import fetchApi from "../fetchApi";
import useAuth from "../../hooks/useAuth";
import { useQuery } from "@tanstack/react-query";

const useQueryImmobilierDetail = (id?: string | number) => {
  const { token } = useAuth();
  return useQuery({
    queryKey: ["ImmoDetails", id],
    queryFn: () => fetchApi(`people?q=${id}`, token).get(),
    refetchOnWindowFocus: false,
  });
};

export default useQueryImmobilierDetail;
