import { Box } from "@mui/material";
import CustomTabPanel from "../../components/layout/customTabPanel/customTabPanel";
import ImmobilierList from "./components/immobilierList";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ImmobilierPage() {
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider", pt: 2 }}>
        <ImmobilierList />
      </Box>
      <CustomTabPanel value={0} index={0}></CustomTabPanel>
    </Box>
  );
}
