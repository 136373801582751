import { Avatar, Chip, IconButton } from "@mui/material";
import { useState } from "react";
import { appHost } from "../../../api/apihost";
import { useQueryNews } from "../../../api/queries";
import Button from "../../../components/button/customButton";
import Datagrid from "../../../components/layout/customTable/Datagrid";
import SectionLayout from "../../../components/layout/sectionLayout";
import { FormCreateEditNews } from "../../../shared";
import { formatDate } from "../../../utils/formatDate";
import { newSelectObjectByProperties } from "../../../utils/selectObjectByProperties";

const NewsList = () => {
  const { data } = useQueryNews();
  const [showCreate, setShowCreate] = useState(false);
  const columns = [
    { label: "id", property: "id" },
    {
      label: "Image",
      property: "image",
      formatter: (text: string) => (
        <IconButton sx={{ p: 0 }}>
          <Avatar
            sx={{
              height: 100,
              width: 100,
            }}
            alt=""
            src={text.startsWith("uploads") ? `${appHost}${text}` : `${text}`}
          />
        </IconButton>
      ),
    },
    { label: "Titre", property: "titre" },
    { label: "Auteur", property: "auteur" },
    { label: "Tag", property: "tag" },

    {
      label: "Date d'enregistrement",
      property: "registered",
      formatter: formatDate,
    },
    {
      label: "Status",
      property: "active",
      formatter: (text: string) => (
        <Chip
          label={text === "1" ? `Actif ` : "Non actif"}
          color={text === "1" ? "success" : "error"}
        />
      ),
    },
  ];
  const list = data?.data || [];
  const selectedRows = newSelectObjectByProperties(list, columns);

  return (
    <div>
      <SectionLayout
        title="Actualités"
        rightContent={
          <Button onClick={() => setShowCreate(true)}>
            Ajouter une actualité
          </Button>
        }
      >
        <FormCreateEditNews
          item={undefined}
          show={showCreate}
          setShow={setShowCreate}
          context="create"
        />
        <Datagrid columns={columns} rows={selectedRows} showButton editButton />
      </SectionLayout>
    </div>
  );
};

export default NewsList;
