import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  IconButton,
  Typography,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { appHost, host } from "../../api/apihost";
import {
  useMutationAcceptLoanApplication,
  useMutationRejectLoanApplication,
} from "../../api/mutations";
import useQueryAutomobileDetail from "../../api/queries/useQueryAutomobileDetail";
import Btn from "../../components/button/customButton";
import Card from "../../components/card/card";
import KeyValueText from "../../components/keyValueText/keyValueText";
import Datagrid from "../../components/layout/customTable/Datagrid";
import Loading from "../../components/layout/loading/Loading";
import SectionLayout from "../../components/layout/sectionLayout";
import { FormCreateEditCar, FormUploadImage } from "../../shared";
import { formatDate } from "../../utils/formatDate";

const AutomobileDetailsPage = () => {
  const { id } = useParams();

  const {
    mutate: rejectLoan,
    isPending,
    isSuccess,
  } = useMutationRejectLoanApplication();
  const {
    mutate: acceptLoan,
    isPending: isPendingAccept,
    isSuccess: isSuccessAccept,
  } = useMutationAcceptLoanApplication();
  const { data, isLoading } = useQueryAutomobileDetail(id);
  const [images, setImages] = useState<any>();
  const [imageTobeUpdated, setImageTobeUpdated] = useState<string | null>("");
  const [showEdit, setShowEdit] = useState(false);

  const car = data?.data || null;

  const columns = [
    { label: "id", property: "id" },
    {
      label: "Image",
      property: "value",
      formatter: (text: string) => (
        <Box
          component="img"
          sx={{
            height: 100,
            width: 100,
            maxHeight: { xs: 100, md: 100 },
            maxWidth: { xs: 100, md: 100 },
          }}
          alt=""
          src={
            text.startsWith("uploads") ? `${appHost}${text}` : `${host}${text}`
          }
        />
      ),
    },
    {
      label: "Détails",
      property: "name",
    },
  ];

  const queryClient = useQueryClient();

  useEffect(() => {
    if (isSuccess || isSuccessAccept)
      queryClient.invalidateQueries({
        queryKey: ["CarDetails"],
      });
    queryClient.invalidateQueries({
      queryKey: ["Automobile"],
    });
  }, [isSuccess, isSuccessAccept]);

  const onPreview = (e: { id: number }) => {
    setImageTobeUpdated(`img${e.id}`);
  };

  const convertCarToimagesInfo = () => {
    let imagesInfo = [];

    if (!car) {
      return [];
    }

    for (let i = 0; i <= 9; i++) {
      const key = `img${i}`;
      const value = car[key];

      imagesInfo.push({ id: i, name: `img ${i}`, value: value || "-" });
    }
    return imagesInfo;
  };
  useEffect(() => {
    setImages(convertCarToimagesInfo());
  }, [car]);

  return (
    <>
      <Loading isLoading={isLoading} />
      {!isLoading && (
        <>
          <FormCreateEditCar
            car={car}
            show={showEdit}
            setShow={setShowEdit}
            context="edit"
          />
          <FormUploadImage
            car={car}
            show={Boolean(imageTobeUpdated)}
            setShow={setImageTobeUpdated}
            context="car"
            imageLink={imageTobeUpdated}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            <Box>
              <Breadcrumbs aria-label="breadcrumb" sx={{ padding: 2 }}>
                <Link
                  color="inherit"
                  to="/"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Tableau de Bord
                </Link>
                <Link
                  color="inherit"
                  to="/project"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Tous Nos projets
                </Link>
                <Typography color="#359444">
                  <span dangerouslySetInnerHTML={{ __html: car?.nom }} />
                </Typography>
              </Breadcrumbs>
            </Box>
            <Box padding={2}>
              <Btn onClick={() => setShowEdit(true)}>Editer ce projet</Btn>
            </Box>
          </Box>
          <Divider />
          <Card title="Détails du projet">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                padding: 2,
              }}
            >
              <IconButton sx={{ p: 0 }}>
                <Avatar
                  alt={car?.nom}
                  src={
                    car?.image.startsWith("uploads")
                      ? `${appHost}${car?.image}`
                      : `${host}${car?.image}`
                  }
                />
              </IconButton>

              <Typography sx={{ px: 3 }}>
                <span dangerouslySetInnerHTML={{ __html: car?.nom }} />
              </Typography>
            </Box>

            <Divider />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                padding: 2,
                paddingX: 3,
              }}
            >
              <KeyValueText title="Description" value={""} isVertical />
              <div dangerouslySetInnerHTML={{ __html: car?.description }} />
            </Box>

            <Divider />

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                padding: 2,
                paddingX: 3,
              }}
            >
              <Box sx={{ width: "50%" }}>
                <KeyValueText
                  title="Date de création"
                  value={`${formatDate(car?.registered)}`}
                  isVertical
                />
              </Box>
              <Box sx={{ width: "50%" }}>
                <KeyValueText
                  title="Status"
                  value={<Chip label={`Actif`} color={"success"} />}
                  isVertical
                />
              </Box>
            </Box>
          </Card>
          <Box sx={{ paddingX: 4 }}>
            <SectionLayout title="Details des images">
              <Datagrid
                columns={columns}
                rows={images || []}
                customTitleButton="Modifier"
                customAction={onPreview}
              />
            </SectionLayout>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: 4,
              paddingBottom: 15,
            }}
          >
            <Box sx={{ width: "40%" }}>
              {car?.status === "open" && (
                <FormControlLabel
                  control={<Checkbox />}
                  label="All documents have been approved and verified"
                />
              )}
            </Box>
            <Box sx={{ width: "45%" }}>
              {car?.kycApproved === "completed" && (
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Button
                    type="submit"
                    fullWidth
                    disabled={isPending || isPendingAccept}
                    variant="contained"
                    size={"large"}
                    sx={{ mt: 3, mb: 2, mx: 2 }}
                    onClick={() => acceptLoan({ loanId: car?.id })}
                  >
                    Approve
                  </Button>

                  <Button
                    type="submit"
                    fullWidth
                    color="error"
                    variant="contained"
                    size={"large"}
                    disabled={isPending || isPendingAccept}
                    onClick={() => rejectLoan({ loanId: car?.id })}
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Reject
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default AutomobileDetailsPage;
