import { Autocomplete, Box, TextField } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import fetchApi from "../api/fetchApi";
import Dialog from "../components/dialog/Dialog";
import CustomInput from "../components/inputs/cutsomInput";
import SelectFree from "../components/inputs/selectFree";
import useAuth from "../hooks/useAuth";
import Editor from "react-simple-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

type FormCreateEditType =
  | {
      context: "edit";
      item: any;
      show: boolean;
      setShow: any;
    }
  | {
      context: "create";
      item: undefined;
      show: boolean;
      setShow: any;
    };

const FormCreateEditNews = ({
  context,
  item,
  show,
  setShow,
}: FormCreateEditType) => {
  const { handleSubmit, control, reset, register } = useForm();
  const [isSaving, setisSaving] = useState(false);
  const { token } = useAuth();
  const [image, setImage] = useState<any>();
  const [html, setHtml] = useState(item?.contenu || "");
  const queryClient = useQueryClient();
  const isEditing = context === "edit";

  function onChange(e: any) {
    setHtml(e.target.value);
  }

  const updateCar = async (data: any) => {
    setisSaving(true);
    const formData = new FormData();

    if (isEditing) {
      formData.append("id", Number(item?.id).toString());
    }
    formData.append("nom", data?.nom);
    formData.append("description", html);

    if (image) {
      formData.append("image", image);
    }

    fetchApi(`news${isEditing ? "/update" : ""}`, token)
      .upload(formData)
      .then((e) => {
        if (e.error) {
          toast(e.messages.message);
        } else if (e.title === "TypeError") {
          toast(e.message);
        } else {
          queryClient.invalidateQueries({
            queryKey: ["CarDetails"],
          });
          queryClient.invalidateQueries({
            queryKey: ["Automobile"],
          });
          handleCloseEdit();
          !isEditing && reset();
          toast("Les  détails de l'actualité ont été modifié", {
            type: "success",
          });
        }
      })
      .catch((err) => toast(err.message, { type: "error" }))
      .finally(() => setisSaving(false));
  };

  const uploadimg = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      console.log(file);
      setImage(e.target.files[0]);
    }
  };

  const handleCloseEdit = () => {
    setShow(false);
  };

  return (
    <Dialog
      title={
        isEditing ? `Modifier ${item?.titre}` : "Créer une nouvelle actualité"
      }
      handleClose={handleCloseEdit}
      open={show}
      confirm={handleSubmit(updateCar)}
      isStoreActivated={false}
      isConfirming={isSaving}
      id={1}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <CustomInput
          control={control}
          id="titre"
          label="Titre"
          name="titre"
          defaultValue={item?.titre || ""}
          disabled={isSaving}
          rules={{
            required: "This field is required",
          }}
          autoFocus
        />
      </Box>

      <Editor
        value={html}
        onChange={onChange}
        defaultValue={item?.contenu || ""}
        style={{ paddingBottom: 5 }}
      />
      <br />
      <br />
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <CustomInput
          control={control}
          id="external_link"
          label="Lien exterieure"
          name="link"
          defaultValue={item?.link || ""}
          disabled={isSaving}
          autoFocus
        />
      </Box>

      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <CustomInput
          control={control}
          id="auteur"
          label="Auteur"
          name="auteur"
          defaultValue={item?.auteur || ""}
          disabled={isSaving}
          autoFocus
        />
      </Box>

      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <CustomInput
          control={control}
          id="tag"
          label="Tag"
          name="tag"
          defaultValue={item?.tag || ""}
          disabled={isSaving}
          autoFocus
        />
      </Box>

      <input type="file" {...register("file")} onChange={uploadimg} />
    </Dialog>
  );
};

export default FormCreateEditNews;
