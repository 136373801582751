import fetchApi from "../fetchApi";
import useAuth from "../../hooks/useAuth";
import { useQuery } from "@tanstack/react-query";

const useQueryNewsDetail = (id?: string | number) => {
  const { token } = useAuth();
  return useQuery({
    queryKey: ["newsDetails", id],
    queryFn: () => fetchApi(`new?q=${id}`, token).get(),
    refetchOnWindowFocus: false,
  });
};

export default useQueryNewsDetail;
